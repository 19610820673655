import useSWR, { mutate } from 'swr';
import { db } from '../db/db';
import { LineItem, Order, OrderToDisplay, Product } from '../types/order.types';
import {
    dataFlowEventHub,
    SWRCacheKeys,
    swrDefaultConfig,
} from '../events/dataFlowEvents';
import { getOrder } from '../db/orders';
import { CashPurchaseDto, CashPurchaseItemDto } from '../apiClient/generated';
import { useCashPurchaseOrder } from './useCashPurchaseOrder';
import { PendingCashPurchaseItem, PendingCashPurchaseOrder } from '../types/cashPurchaseOrders.types';
import order from '../pages/order';
import { getShortWbPono } from '../components/utils/wbPono';
import { Line } from 'recharts';

dataFlowEventHub.on('orderChanged', (orderId) =>
    mutate([SWRCacheKeys.order, orderId], undefined, { revalidate: true }),
);

const fetchDbData = (orderId: number) => getOrder(orderId);

export const usePrintHeaderDetails = ({ 
    orderToDisplayCash,
    orderToDisplay}:
    {
        orderToDisplayCash?: CashPurchaseDto | PendingCashPurchaseOrder | undefined,
        orderToDisplay?: OrderToDisplay 
    }

) => {

    
    let 
        currency,
        shortWbPoNoOrTitle,
        displayDeliveryDate,
        numberOfProducts,
        products,
        orderType,
        hasWbPoNo

    if (orderToDisplay) { 
        displayDeliveryDate = orderToDisplay?.rfq.deliveryDate
        numberOfProducts = orderToDisplay.rfq.lineItems.length 
        products = orderToDisplay.rfq.lineItems 
        hasWbPoNo = orderToDisplay?.isSsPoNumberAvailableForOrder 
        shortWbPoNoOrTitle = hasWbPoNo ?
            "#" + getShortWbPono(orderToDisplay.rfq.wbPono) :
            orderToDisplay.draftName
        currency = orderToDisplay.rfq.contractCurrency
        orderType = orderToDisplay.type
        
    } else if (orderToDisplayCash) { 
        displayDeliveryDate = orderToDisplayCash.deliveryDate
        numberOfProducts = orderToDisplayCash.items.length
        products = orderToDisplayCash.items
        shortWbPoNoOrTitle = 'wbPoNo' in orderToDisplayCash ?
            "#" + getShortWbPono(orderToDisplayCash.wbPoNo)   :
            'Cash Draft'
        currency = orderToDisplayCash.currency
        orderType = "Cash"
        
    } 

    return {
        currency,
        shortWbPoNoOrTitle,
        displayDeliveryDate,
        numberOfProducts,
        products,
        orderType,
        hasWbPoNo
    };
};

import React from 'react';
import { TableHeadRoot } from '../../ProductsList/TableHead';
import styles from './CashPurchaseItemsList.module.css';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import {
    getProductsSortedByCategories,
    OrderItemCategoriesReMapped,
} from '../../ProductsList/utils/productsListParser';
import productListStyles from '../../ProductsList/ProductsList.module.css';
import { CashPurchaseCategoryRow } from './CashPurchaseCategoryRow';
import { EmptyState } from '../../ListEmptyState/EmptyState';
import Cart from '../../../public/gfx/cart.svg';
import {
    CashPurchaseDto,
    CashPurchaseItemDto,
    CashPurchaseOrigin,
} from '../../../apiClient/generated';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../../../types/cashPurchaseOrders.types';

interface CashPurchaseItemsListProps {
    orderToDisplay?: CashPurchaseDto | PendingCashPurchaseOrder;
    readOnly?: boolean;
}

export function isCashPurchaseItemDto(
    item: PendingCashPurchaseItem | CashPurchaseItemDto,
): item is CashPurchaseItemDto {
    return (item as CashPurchaseItemDto).category !== undefined;
}

export const CashPurchaseItemsList: React.FC<CashPurchaseItemsListProps> = ({
    orderToDisplay,
    readOnly,
}) => {
    const { pendingCashPurchaseOrder } = usePendingCashPurchaseOrder();

    const mapCashPurchaseOrderToMutualCategoryType = (
        items: PendingCashPurchaseItem[] | CashPurchaseItemDto[],
    ): OrderItemCategoriesReMapped[] => {
        return items.map((item) => {
            if (isCashPurchaseItemDto(item)) {
                return {
                    ...item,
                    categoryLevel3: {
                        text: item.category,
                        number: item.categoryLevel3,
                    },
                    isPending: false,
                };
            } else {
                return {
                    ...item,
                    id: Number(item.itemNumber),
                    isPending: true,
                };
            }
        });
    };

    const cashPurchaseOrderWithMutualCategoryType =
        mapCashPurchaseOrderToMutualCategoryType(
            orderToDisplay?.items ?? pendingCashPurchaseOrder?.items ?? [],
        );

    const productsSortedByCategories = getProductsSortedByCategories(
        cashPurchaseOrderWithMutualCategoryType,
    );

    return (
        <>
            <TableHeadRoot compact={readOnly}>
                {readOnly ? (
                    <>
                        <p className={styles.tableCell}>Item</p>
                        <p className={styles.tableCell}>Packing</p>
                        <p className={styles.tableCell}>
                            {orderToDisplay?.origin === CashPurchaseOrigin.AX
                                ? 'Discounted price'
                                : 'Price'}
                        </p>
                        <p className={styles.tableCell}>Quantity</p>
                        <p className={styles.tableCell}>Discounted Total</p>
                        <p className={styles.tableCell} />
                    </>
                ) : (
                    <>
                        <p className={styles.tableCell}>Item</p>
                        <p className={styles.tableCell}>Unit</p>
                        <p className={styles.tableCell}>Unit Price</p>
                        <p className={styles.tableCell}>Purchased</p>
                        <p className={styles.tableCell}>Discounted Total</p>
                        <p className={styles.tableCell} />
                    </>
                )}
            </TableHeadRoot>
            <div className={productListStyles.container}>
                {productsSortedByCategories.map((item) => (
                    <CashPurchaseCategoryRow
                        category={item}
                        key={item.categoryNumber}
                        readOnly={readOnly}
                    />
                ))}
                {productsSortedByCategories.length === 0 && (
                    <EmptyState
                        image={<Cart />}
                        title="No Products Added"
                        subTitle={
                            readOnly
                                ? ' '
                                : 'Click on “Add Item” in the top right of the screen to start adding items to your Cash Purchase order'
                        }
                    />
                )}
            </div>
        </>
    );
};

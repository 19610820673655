import React, { useMemo, useState } from 'react';
import { useProducts } from '../../../hooks/useProducts';
import { getProductsSortedByCategories } from '../utils/productsListParser';
import productsListsStyles from '../ProductsList.module.css';
import { CategoriesBar } from './CategoriesBar';
import { TableHead } from './TableHead';
import { ProductCategoryRow } from './ProductCategoryRow';
import { OrderToDisplay, Product } from '../../../types/order.types';
import {
    Page,
    useCategoriesBarScrollFunctions,
} from '../../../hooks/useCategoriesBarScrollFunctions';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import { FlexSpacer } from '../../FlexSpacer/FlexSpacer';
import { ProductCategory } from '../ProductsList';
import { useReceivedOrder } from '../../../hooks/useReceivedOrder';
import modalStyles from '../../Modal/Modal.module.css';
import Button from '../../Button/Button';
import { useRouter } from 'next/router';
import useOrderType from '../../../hooks/useOrderType';
import AddIcon from '../../../public/gfx/uiIcons/add.svg';
import styles from '../../Receival/ReceivalModals/Receival.module.css';
import StepsProgressBar from '../../StepsProgressBar/StepsProgressBar';

export const ReceivedProductsChecklist: React.FC<{
    orderToDisplay: OrderToDisplay;
    setCommentViewIsActive: React.Dispatch<
        React.SetStateAction<{
            isOpen: boolean;
            itemNumber?: string | undefined;
        }>
    >;
    steps: number;
    onBack: () => void;
    onContinue: () => void;
}> = ({
    orderToDisplay,
    setCommentViewIsActive,
    steps,
    onBack,
    onContinue,
}) => {
    const { push } = useRouter();
    const { orderIdFromQuery, activeOrderType } = useOrderType();
    const { data: allProducts } = useProducts();
    const { data: receivedOrder } = useReceivedOrder(orderToDisplay.orderId);

    const [receivedCategories, setReceivedCategories] = useState<
        ProductCategory<Product>[]
    >([]);

    const productsSortedByCategories: ProductCategory<Product>[] =
        useMemo(() => {
            const sortedProducts = getProductsSortedByCategories(allProducts);

            const sortedProductsWithOrderedAmount: ProductCategory<Product>[] =
                [];
            for (const category of sortedProducts) {
                const products: Product[] = [];
                for (const product of category.products) {
                    const isProductIncludedInReceivedOrder = Boolean(
                        receivedOrder?.receivedLineItems[product.itemNumber],
                    );

                    if (!isProductIncludedInReceivedOrder) {
                        continue;
                    }

                    const parsedProduct: Product = {
                        ...product,
                        orderedQuantity: orderToDisplay.rfq.lineItems.find(
                            (item) => item.itemNumber === product.itemNumber,
                        )?.quantity,
                    };

                    products.push(parsedProduct);
                }

                if (products.length === 0) {
                    continue;
                }

                const parsedCategory: ProductCategory<Product> = {
                    ...category,
                    products,
                };

                sortedProductsWithOrderedAmount.push(parsedCategory);
            }
            return sortedProductsWithOrderedAmount;
        }, [
            allProducts,
            receivedOrder?.receivedLineItems,
            orderToDisplay.rfq.lineItems,
        ]);

    const areAllCategoriesReceived = useMemo(() => {

        return receivedCategories.length === productsSortedByCategories.length;
    }, [receivedCategories, productsSortedByCategories]);

    const [showConfirmed, setShowConfirmed] = useState(false);

    const {
        productListRef,
        stickyTableHeadRef,
        isCategoriesBarSticky,
        containerWidth,
        stickyTableHeadHeight,
        headerRef,
        selectCategory,
        selectedCategory,
        productListScrolledToTheEnd,
        categoriesBarsScrollLeft,
        setCategoriesBarsScrollLeft,
        forceUpdateCategoriesPositions,
    } = useCategoriesBarScrollFunctions(
        productsSortedByCategories,
        142,
        Page.receivalChecklist,
    );

    const renderCategoriesBarAndTableHead = () => (
        <>
            <CategoriesBar
                productsSortedByCategories={productsSortedByCategories}
                selectCategory={selectCategory}
                selectedCategory={selectedCategory}
                productListScrolledToTheEnd={productListScrolledToTheEnd}
                isCategoriesBarSticky={isCategoriesBarSticky}
                categoriesBarsScrollLeft={categoriesBarsScrollLeft}
                setCategoriesBarsScrollLeft={setCategoriesBarsScrollLeft}
            />
            <TableHead
                setShowConfirmed={setShowConfirmed}
                showConfirmed={showConfirmed}
                receivedOrder={receivedOrder}
            />
        </>
    );

    return (
        <>
            {isCategoriesBarSticky && (
                <div
                    ref={stickyTableHeadRef}
                    style={{
                        maxWidth: containerWidth ?? '100%',
                        width: containerWidth ?? '100%',
                    }}
                    className={[
                        productsListsStyles.productListInReceivalFlowContainer,
                        productsListsStyles.sticky,
                    ].join(' ')}
                >
                    {renderCategoriesBarAndTableHead()}
                </div>
            )}
            <div
                ref={productListRef}
                className={
                    productsListsStyles.productListInReceivalFlowContainer
                }
            >
                <div ref={headerRef}>
                    <div className={styles.header}>
                        <ModalHeader
                            title={
                                <div className={styles.stepsProgressBar}>
                                    <StepsProgressBar
                                        currentStep={2}
                                        totalStepsLength={steps}
                                        parentRemounts
                                    />
                                </div>
                            }
                            subTitle="Checklist"
                            description="Please confirm received items and quantities by checking them off the list."
                        />
                    </div>
                    <div className={styles.button}>
                        <Button
                            text="Add Item"
                            greyCta
                            smallRounded
                            icon={
                                <AddIcon height="12" width="12" stroke="#000" />
                            }
                            onClick={() =>
                                push(
                                    `/order/${activeOrderType.toLowerCase()}/${orderIdFromQuery}/receival/add`,
                                )
                            }
                        />
                    </div>
                </div>

                <div>
                    {isCategoriesBarSticky && (
                        <div style={{ height: stickyTableHeadHeight }} />
                    )}
                    {!isCategoriesBarSticky &&
                        renderCategoriesBarAndTableHead()}
                    <div onClick={forceUpdateCategoriesPositions}>
                        {productsSortedByCategories.map((item) => {
                            return (
                                <ProductCategoryRow
                                    productCategory={item}
                                    key={item.categoryName}
                                    setCommentViewIsActive={
                                        setCommentViewIsActive
                                    }
                                    showConfirmed={showConfirmed}
                                    setReceivedCategories={
                                        setReceivedCategories
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <FlexSpacer />

            <div className={modalStyles.squareActionButton}>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button text="Back" onClick={onBack} secondary />
                </div>
                <div className={modalStyles.squareActionButtonChild}>
                    <Button
                        text="Continue"
                        onClick={onContinue}
                        disabled={!areAllCategoriesReceived}
                        primary={areAllCategoriesReceived}
                    />
                </div>
            </div>
        </>
    );
};

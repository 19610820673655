
import { currencyFormatter } from '../utils/numberFormat';

import { useEffect, useMemo, useState } from 'react';  
import {
    CashPurchaseDto,
    CashPurchaseOrigin,
    ReceivalItemDto,
} from '../../apiClient/generated';

import styles from './OrderPDF.module.css';
import { OrderToDisplay, Product } from '../../types/order.types';
import { useVesselMetadata } from '../../hooks/useVesselMetadata';
import PricePerPackOrderPDF from './PricePerPackOrderPDF';

interface SingleItemRowProps {
    product: Product;
    orderToDisplay: OrderToDisplay;
    lineItem?: ReceivalItemDto;
    rowNumber?: number
    sortedProductsLength?: number
    categoryRowNumber: number
}

export const OrderPDFReceivedProductRow: React.FC<SingleItemRowProps> = ({
    product, 
    orderToDisplay,
    lineItem,
    rowNumber,
    sortedProductsLength,
    categoryRowNumber
}) => {
    
    const {itemName,  itemNumber, unitOfMeasure, estimatedPrice } = product
    
    const rfqLineItem = useMemo(
        () =>
            orderToDisplay.rfq.lineItems.find(
                (item) => item.itemNumber === lineItem?.itemNumber
            ),
        [orderToDisplay]
    )

    const { data: { vesselCurrency }} = useVesselMetadata()
    
    const receivedTotal = (lineItem?.price ?? 0) * (lineItem?.quantity ?? 0);
 
    return (
        <div className={styles.tableRow}> 
            <div className={[styles.tableCell, styles.s1].join(' ')}>
                { rowNumber }
            </div>
            
            <div className={styles.tableCell}>
                <div className={styles.badge}>{ itemNumber }</div>
                { itemName || lineItem?.itemName }
            </div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>{ rfqLineItem?.unitOfMeasure }</div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>
               { lineItem && (<PricePerPackOrderPDF
                    unitOfMeasure={lineItem?.unitOfMeasure ?? ''}
                    price={lineItem.price}
                    isEstimatedPrice={false}
                    currency={vesselCurrency}
                /> )}
                {rfqLineItem?.estimatedPrice}
            </div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>{ rfqLineItem?.quantity }{" "}{ unitOfMeasure }</div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>
                {currencyFormatter(receivedTotal, vesselCurrency)}
            </div>
            <div className={styles.tableCell}>{ lineItem?.comment }</div>
        </div>
         
    );
};

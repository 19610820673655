import { ProductCategory } from '../ProductsList/ProductsList'; 
import { PendingCashPurchaseItem, PendingCashPurchaseOrder } from '../../types/cashPurchaseOrders.types';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import { currencyFormatter } from '../utils/numberFormat';
import { OrderItemCategoriesReMapped } from '../ProductsList/utils/productsListParser';
import { useItemsListOrderContext } from '../CashPurchaseItemsOverview/ItemsListContext';
import { OrderPDFCashSingleRow } from './OrderPDFCashSingleRow';
import styles from './OrderPDF.module.css';
import { OrderToDisplay } from '../../types/order.types';
import { CashPurchaseDto } from '../../apiClient/generated';

interface CashPropsRow {
  category: ProductCategory< PendingCashPurchaseItem | OrderItemCategoriesReMapped >
  readOnly?: boolean;
  orderToDisplayCash: CashPurchaseDto | PendingCashPurchaseOrder;
  categoryRowNumber: number;
  productsSortedByCategoriesCashLengths:number[]
}

const OrderPDFCash:React.FC<CashPropsRow> = ({
  category,
  readOnly,
  orderToDisplayCash,
  categoryRowNumber,
  productsSortedByCategoriesCashLengths
}) => { 
  
    const { pendingCashPurchaseOrder } = usePendingCashPurchaseOrder(); 
    const getCategorySummary = () => {
        const sum = category.products.reduce(
            (prev, curr) => {
                const priceSumBeforeDiscount =
                    prev.priceSum + curr.price * curr.quantity;

                return {
                    quantitySum: prev.quantitySum + curr.quantity,
                    priceSum: priceSumBeforeDiscount,
                };
            },
            { quantitySum: 0, priceSum: 0 },
        );

        return sum;
    };

    const { quantitySum, priceSum } = getCategorySummary();
    
    const discount = pendingCashPurchaseOrder?.cashDiscount ?? 0;

    // for row sequencing
    const startingPointOfRowCount = productsSortedByCategoriesCashLengths.slice(0, categoryRowNumber).reduce((total, num) => total + num, 0)
    

    return (
        <> 
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <div className={styles.tableCellParentCategory}>
                  {category.categoryName} 
              </div> 
            </div> 
            {
              category.products.map((item, index) => ( 
                <OrderPDFCashSingleRow
                  key={item.itemNumber}
                  rowNumber={ index+1+startingPointOfRowCount }
                  categoryRowNumber = {categoryRowNumber}
                  orderToDisplayCash={orderToDisplayCash}
                  sortedProductsLength = { category.products.length }
                  product={item}
                  readOnly={readOnly}
                /> 
            ))} 
          </div>
        </>
    );


}

export default OrderPDFCash
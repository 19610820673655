import { useRouter } from 'next/router';
import { usePendingCashPurchaseOrder } from '../../../hooks/usePendingCashPurchaseOrder';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../../../types/cashPurchaseOrders.types';
import { adjustAmountToUnitOfMeasure } from '../../../utils/adjustAmountToUnitOfMeasure';
import DecimalInput from '../../DecimalInput/DecimalInput';
import PricePerPack from '../../ProductsList/PricePerPack';
import productListStyles from '../../ProductsList/ProductsList.module.css';
import { currencyFormatter } from '../../utils/numberFormat';
import tableHeadStyles from './CashPurchaseItemsList.module.css';
import { useCashPurchaseOrder } from '../../../hooks/useCashPurchaseOrder';
import CommentButton from '../../CommentButton/CommentButton';
import { useEffect, useState } from 'react';
import { Portal } from '@radix-ui/react-portal';
import { AddCommentToLineItem } from '../../AddCommentToLineItem/AddCommentToLineItem';
import { ModalHeader } from '../../ModalHeader/ModalHeader';
import Modal from '../../Modal/Modal';
import CloseIcon from '../../../public/gfx/uiIcons/closeGrey.svg';
import { SmallConfirmationModal as ConfirmDeleteCashPurchaseOrderItem } from '../../SmallConfirmationModal/SmallConfirmationModal';
import {
    CashPurchaseDto,
    CashPurchaseOrigin,
} from '../../../apiClient/generated';
import {
    isCashPurchaseInViewOnly,
    OrderItemCategoriesReMapped,
} from '../../ProductsList/utils/productsListParser';

interface SingleItemRowProps {
    product: PendingCashPurchaseItem | OrderItemCategoriesReMapped;
    readOnly?: boolean;
}

export const CashPurchaseSingleItemRow: React.FC<SingleItemRowProps> = ({
    product,
    readOnly,
}) => {
    const [
        isConfirmDeleteCashPurchaseOrderItemModalIsOpen,
        setConfirmDeleteCashPurchaseOrderItemModalIsOpen,
    ] = useState(false);
    const { pendingCashPurchaseOrder, updateItem, deleteItem } =
        usePendingCashPurchaseOrder();
    const { query } = useRouter();
    const orderIdFromQuery = Number(query.cashPurchaseId);
    const { cashPurchaseOrder: orderToDisplay } =
        useCashPurchaseOrder(orderIdFromQuery);

    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [commentInputValue, setCommentInputValue] = useState<string>(
        product.comment,
    );

    const getPriceAfterDiscount = (
        order?: PendingCashPurchaseOrder | CashPurchaseDto,
    ) => {
        const isPriceDiscountedByAX = order?.origin === CashPurchaseOrigin.AX;
        const discount = order?.cashDiscount ?? 0;
        const priceBeforeDiscount = product.price * product.quantity;
        return (
            priceBeforeDiscount * (!isPriceDiscountedByAX ? 1 - discount : 1)
        );
    };

    const productIsPendingCashPurchaseItem = !isCashPurchaseInViewOnly(product);

    const addCommentToLineItem = async () => {
        if (productIsPendingCashPurchaseItem) {
            await updateItem(product, { comment: commentInputValue });
            setIsCommentModalOpen(false);
        }
    };

    useEffect(() => {
        setCommentInputValue(product.comment);
    }, []);

    return (
        <div
            className={[
                readOnly ? productListStyles.compact : '',
                productListStyles.singleProductRow,
            ].join(' ')}
        >
            <Portal>
                <Modal
                    isOpen={isCommentModalOpen}
                    onClose={() => setIsCommentModalOpen(false)}
                    noHeader
                >
                    <AddCommentToLineItem
                        commentContent={commentInputValue}
                        setCommentContent={setCommentInputValue}
                        addComment={addCommentToLineItem}
                        inputTitle={`optional comment to line item: ${product.itemName}`}
                        customHeader={
                            <ModalHeader
                                subTitle={
                                    readOnly
                                        ? `Comment attached to ${product.itemName}`
                                        : 'Add a comment to line item'
                                }
                            />
                        }
                        onBack={
                            readOnly
                                ? undefined
                                : () => setIsCommentModalOpen(false)
                        }
                        customActionButtonCaption={
                            product.comment ? 'Update' : 'Add'
                        }
                        viewOnly={readOnly}
                        customBackButtonCaption="Cancel"
                        cashPurchaseItem
                    />
                </Modal>
            </Portal>
            <Portal>
                <ConfirmDeleteCashPurchaseOrderItem
                    isOpen={isConfirmDeleteCashPurchaseOrderItemModalIsOpen}
                    onClose={() =>
                        setConfirmDeleteCashPurchaseOrderItemModalIsOpen(false)
                    }
                    onAbort={() =>
                        setConfirmDeleteCashPurchaseOrderItemModalIsOpen(false)
                    }
                    onConfirm={async () => {
                        if (productIsPendingCashPurchaseItem) {
                            setConfirmDeleteCashPurchaseOrderItemModalIsOpen(
                                false,
                            );
                            await deleteItem(product);
                        }
                    }}
                    confirmBtnText="Delete item"
                    cancelBtnText="Cancel"
                    title="Are you sure?"
                    subtitle={`Are you sure you want to delete ${product.itemName} from your Cash Purchase Order?`}
                />
            </Portal>
            <div
                className={[
                    tableHeadStyles.tableCell,
                    productListStyles.singleProductCell,
                ].join(' ')}
            >
                <p className={productListStyles.primary}>{product.itemName}</p>
                <p className={productListStyles.secondary}>
                    {product.itemNumber}
                </p>
            </div>
            <div
                className={[
                    tableHeadStyles.tableCell,
                    productListStyles.singleProductCell,
                ].join(' ')}
            >
                <p className={productListStyles.secondary}>
                    {product.unitOfMeasure ?? '-'}
                </p>
            </div>
            <div
                className={[
                    tableHeadStyles.tableCell,
                    productListStyles.singleProductCell,
                    readOnly
                        ? productListStyles.directionColumn
                        : productListStyles.directionRow,
                ].join(' ')}
            >
                {readOnly ? (
                    <PricePerPack
                        unitOfMeasure={product.unitOfMeasure}
                        price={product.price}
                        isEstimatedPrice={false}
                        currency={orderToDisplay?.currency}
                    />
                ) : (
                    <DecimalInput
                        value={product.price}
                        onChange={(value) => {
                            if (productIsPendingCashPurchaseItem) {
                                updateItem(product, { price: Number(value) });
                            }
                        }}
                        width={70}
                        isCurrencyInput
                    />
                )}
                {readOnly || (
                    <p className={productListStyles.secondary}>
                        &nbsp;{pendingCashPurchaseOrder?.currency}
                    </p>
                )}
            </div>
            <div
                className={[
                    tableHeadStyles.tableCell,
                    productListStyles.singleProductCell,
                    productListStyles.directionRow,
                ].join(' ')}
            >
                {readOnly ? (
                    <p className={productListStyles.primary}>
                        {product.quantity}
                    </p>
                ) : (
                    <DecimalInput
                        value={product.quantity}
                        onChange={(value) => {
                            if (productIsPendingCashPurchaseItem) {
                                updateItem(product, {
                                    quantity: Number(value),
                                });
                            }
                        }}
                        width={70}
                        onBlurValueConverter={(value) =>
                            adjustAmountToUnitOfMeasure(
                                product.unitOfMeasure,
                                value,
                            )
                        }
                    />
                )}
                <p className={productListStyles.secondary}>
                    &nbsp;{product.unitOfMeasure}
                </p>
            </div>
            <div
                className={[
                    tableHeadStyles.tableCell,
                    productListStyles.singleProductCell,
                ].join(' ')}
            >
                {currencyFormatter(
                    getPriceAfterDiscount(
                        readOnly ? orderToDisplay : pendingCashPurchaseOrder,
                    ).toFixed(2),
                    readOnly
                        ? orderToDisplay?.currency
                        : pendingCashPurchaseOrder?.currency,
                )}
            </div>
            <div
                className={[
                    readOnly ? '' : tableHeadStyles.tableCellButtons,
                    productListStyles.singleProductCell,
                ].join(' ')}
            >
                <CommentButton
                    onClick={() => {
                        setIsCommentModalOpen(true);
                    }}
                    isActive={readOnly ? Boolean(product.comment) : true}
                    hasComment={Boolean(product.comment)}
                />
                {readOnly || (
                    <div
                        className={productListStyles.closeIconEndingRow}
                        onClick={() =>
                            setConfirmDeleteCashPurchaseOrderItemModalIsOpen(
                                true,
                            )
                        }
                    >
                        <CloseIcon width="8" height="8" stroke="#000" />
                    </div>
                )}
            </div>
        </div>
    );
};

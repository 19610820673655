import { useRouter } from 'next/router';
import { usePendingCashPurchaseOrder } from '../../hooks/usePendingCashPurchaseOrder';
import {
    PendingCashPurchaseItem,
    PendingCashPurchaseOrder,
} from '../../types/cashPurchaseOrders.types';
import { adjustAmountToUnitOfMeasure } from '../../utils/adjustAmountToUnitOfMeasure';
import DecimalInput from '../DecimalInput/DecimalInput';
import PricePerPack from '../ProductsList/PricePerPack';
import productListStyles from '../ProductsList/ProductsList.module.css';
import { currencyFormatter } from '../utils/numberFormat';
import tableHeadStyles from '../../components/CashPurchaseItemsOverview/CashPurchaseItemsList/CashPurchaseItemsList.module.css';
import { useCashPurchaseOrder } from '../../hooks/useCashPurchaseOrder'; 
import { useEffect, useState } from 'react';  
import {
    CashPurchaseDto,
    CashPurchaseOrigin,
} from '../../apiClient/generated';
import {
    isCashPurchaseInViewOnly,
    OrderItemCategoriesReMapped,
} from '../ProductsList/utils/productsListParser';
import styles from './OrderPDF.module.css';
import PricePerPackOrderPDF from './PricePerPackOrderPDF';

interface SingleItemRowProps {
    product: PendingCashPurchaseItem | OrderItemCategoriesReMapped;
    readOnly?: boolean;
    rowNumber?: number; 
    sortedProductsLength?: number; 
    categoryRowNumber: number 
    orderToDisplayCash: CashPurchaseDto | PendingCashPurchaseOrder;
}

export const OrderPDFCashSingleRow: React.FC<SingleItemRowProps> = ({
    product,
    readOnly,
    rowNumber,
    sortedProductsLength,
    categoryRowNumber,
    orderToDisplayCash
}) => {
    
    
    
    const { query } = useRouter();
    const orderIdFromQuery = Number(query.cashPurchaseId);
    const { cashPurchaseOrder: orderToDisplay } =
        useCashPurchaseOrder(orderIdFromQuery);
     

    const getPriceAfterDiscount = (
        order?: PendingCashPurchaseOrder | CashPurchaseDto,
    ) => {
        const isPriceDiscountedByAX = order?.origin === CashPurchaseOrigin.AX;
        const discount = order?.cashDiscount ?? 0;
        const priceBeforeDiscount = product.price * product.quantity;
        return (
            priceBeforeDiscount * (!isPriceDiscountedByAX ? 1 - discount : 1)
        );
    };
 

     


    const productIsPendingCashPurchaseItem = !isCashPurchaseInViewOnly(product);
    const {itemName, comment, itemNumber, unitOfMeasure, price, quantity, } = product
     
    return (
        <div className={styles.tableRow}> 
            <div className={[styles.tableCell, styles.s1].join(' ')}>{ rowNumber }</div>
            
            <div className={styles.tableCell}>
                <div className={styles.badge}>{ itemNumber }</div>
                { itemName }
            </div>

            <div className={[styles.tableCell, styles.s2].join(' ')}>{ unitOfMeasure }</div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>
                <PricePerPackOrderPDF
                    unitOfMeasure={unitOfMeasure}
                    price={price}
                    isEstimatedPrice={false}
                    currency={orderToDisplay?.currency}
                />
            </div>
            <div className={[styles.tableCell, styles.s2].join(' ')}>{ quantity }{" "}{ unitOfMeasure }</div>

            <div className={[styles.tableCell, styles.s2].join(' ')}>
                {currencyFormatter(getPriceAfterDiscount(orderToDisplay).toFixed(2),orderToDisplay?.currency)}
            </div>
            <div className={styles.tableCell}><i>{ comment ? comment : "" }</i></div>
        </div>
       
    );
};
